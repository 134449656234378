import React from "react";
import Input from "../common/formComponents/input/Input";
import Button from "../common/buttons/button/Button";
import LoginStyled from "./Login.style";

import { filledArrowIcon } from "../../assets/images";
import loginBackground from "../../assets/images/external-tournaments/login-background.png";

import useLoginHook from "./Login.hook";

function Login() {
  const { translations, onFormSubmit, formInputs, onRecoverPasswordClick, onSignUpClick } = useLoginHook();

  return (
    <>
      <img
        alt="banner"
        className="banner"
        src={loginBackground}
      />
      <LoginStyled>
        <h1 className="title">{translations.heading}</h1>
        {/* <div className="social-login">
        <FacebookButton
          btnText={translations.facebookButton}
          onClick={onOauthSubmit("facebook")}
        />
        <GoogleButton
          btnText={translations.googleButton}
          onClick={onOauthSubmit("google")}
        />
      </div> */}
        <form onSubmit={onFormSubmit}>
          <div className="inputs">
            {formInputs.map((input) => (
              <Input
                item={input}
                key={input.id}
              />
            ))}
          </div>
          <div className="captcha-info">
            This site is protected by reCAPTCHA and the
            <a
              href="https://policies.google.com/privacy"
              className="captcha-link"
            >
              Privacy Policy
            </a>
            and
            <a
              href="https://policies.google.com/terms"
              className="captcha-link"
            >
              Terms of Service
            </a>
            apply.
          </div>
          <div>
            <p className="forget-password">{translations.forgetPassword}</p>
            <Button
              type="labelAndIconButton"
              label={translations.recoverPassword}
              variant="ghost"
              icon={filledArrowIcon}
              alt="icon"
              iconPosition="right"
              onClick={onRecoverPasswordClick}
            />
          </div>
          <div className="buttons">
            <Button
              type="labelButton"
              label={translations.signup}
              variant="secondary"
              position="center"
              width="100%"
              onClick={onSignUpClick}
            />
            <Button
              submitButton
              type="labelButton"
              label={translations.submitButton}
              variant="primary"
              position="center"
              width="100%"
            />
          </div>
        </form>
      </LoginStyled>
    </>
  );
}

export default Login;
