import React, { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import PageContent from "../pageContent.type";
import { SignUpAuthPayload } from "../Signup.type";
import { FormComponentInput } from "../../../common/formComponents/formComponents.types";
import passwordRegExp from "../../../../helpers/password-regex";
import Button from "../../../common/buttons/button/Button";
import renderFormField from "../../../common/formComponents/render-form-field";

interface SignUpAuthStageProps {
  authStageState?: SignUpAuthPayload;
  setAuthStageState: (state: SignUpAuthPayload) => void;
  stageOneFinished?: boolean;
  setStageOneFinished: (state: boolean) => void;
}

export default function SignUpAuthStage({ authStageState, setAuthStageState, stageOneFinished, setStageOneFinished }: SignUpAuthStageProps) {
  const { t } = useTranslation();
  const translations: PageContent = t("signup", { returnObjects: true });

  const params = useParams();

  const yupRegisterPasswordValidation = Yup.string()
    .required(translations.errorRequired)
    .min(8, translations.errorPasswordValid)
    .max(128, translations.errorPasswordTooLong)
    .matches(passwordRegExp, translations.errorPasswordValid);

  const validationSchema = Yup.object().shape({
    password: yupRegisterPasswordValidation,
    repeatPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], translations.errorConfirmPassword)
      .required(translations.errorRequired),
    email: Yup.string().email(translations.errorEmailValid).required(translations.errorRequired),
  });

  const formik = useFormik({
    initialValues: {
      referralCode: `${window.location.origin}/signup/${params.referralCode}`,
      email: "",
      password: "",
      repeatPassword: "",
    },
    validationSchema,
    onSubmit: () => {
      setStageOneFinished(true);
    },
  });

  useEffect(() => {
    setAuthStageState({
      type: "email",
      emailPayload: {
        email: formik.values.email,
        password: formik.values.password,
      },
      referralKey: params.referralCode,
    });
  }, [formik.values]);

  const formInputs: FormComponentInput[] = [
    {
      type: "email",
      label: translations.email || "",
      id: "signup-email",
      placeholder: translations.emailPlaceholder || "",
      name: "email",
      value: formik.values.email,
      onChange: formik.handleChange,
      error: (formik.touched.email && formik.errors.email) || "",
      onBlur: formik.handleBlur,
    },
    {
      type: "password",
      label: translations.password || "",
      id: "signup-password",
      placeholder: translations.passwordPlaceholder || "",
      name: "password",
      value: formik.values.password,
      onChange: formik.handleChange,
      error: (formik.touched.password && formik.errors.password) || "",
      onBlur: formik.handleBlur,
    },
    {
      type: "password",
      label: translations.confirmPassword || "",
      id: "signup-confirmpassword",
      placeholder: translations.confirmPasswordPlaceholder || "",
      name: "repeatPassword",
      value: formik.values.repeatPassword,
      onChange: formik.handleChange,
      error: (formik.touched.repeatPassword && formik.errors.repeatPassword) || "",
      onBlur: formik.handleBlur,
    },
  ];

  const referralCodeInput: FormComponentInput = {
    type: "text",
    label: translations.referral_link || "",
    id: "signup-referral-link",
    placeholder: translations.referral_link || "",
    name: "referralCode",
    value: formik.values.referralCode,
    onChange: formik.handleChange,
    error: (formik.touched.referralCode && formik.errors.referralCode) || "",
    onBlur: formik.handleBlur,
    disabled: true,
  };

  const generateInputs = useCallback(() => {
    if (params.referralCode) {
      formInputs.unshift(referralCodeInput);
    }
    return formInputs.map((input) => renderFormField(input));
  }, [params, formInputs]);

  return (
    <>
      {/* <div className="social-login">
        {(!stageOneFinished || authStageState?.type === "facebook") && (
          <FacebookButton
            disabled={!!stageOneFinished && !!authStageState}
            btnText={translations.facebookButton}
            onClick={() => {
              setAuthStageState({
                type: "facebook",
                referralKey: params.referralCode,
              });
              setStageOneFinished(true);
            }}
          />
        )}
        {(!stageOneFinished || authStageState?.type === "google") && (
          <GoogleButton
            disabled={!!stageOneFinished && !!authStageState}
            btnText={translations.googleButton}
            onClick={() => {
              setAuthStageState({
                type: "google",
                referralKey: params.referralCode,
              });
              setStageOneFinished(true);
            }}
          />
        )}
      </div> */}
      {(!stageOneFinished || authStageState?.type === "email") && (
        <form onSubmit={formik.handleSubmit}>
          <div className="inputs">
            <div className="column">{generateInputs()}</div>
          </div>
          {!stageOneFinished && (
            <Button
              type="labelButton"
              submitButton
              label={translations.nextStepButton}
              variant="blue"
            />
          )}
        </form>
      )}
    </>
  );
}
