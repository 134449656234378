import React from "react";
import "./userAlreadyRegistered.css";
import { Button } from "react-bootstrap";
import ExternalTournamentRegistrationModal from "./external-tournament-registration-confirmation/registration-modal";

export default function UserAlreadyRegistered({ tournamentName, redirectUrl, showModal }: { tournamentName: string; redirectUrl: string; showModal: boolean }) {
  const goToRedirectUrl = () => {
    const newWindow = window.open(redirectUrl, "_blank", "noopener,noreferrer");
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  return (
    <div className="user-already-registered">
      <h1>Dziekujemy!</h1>
      <p>Zapisano Cię na turniej {tournamentName}</p>
      <div className="registration-button">
        <div>
          <Button
            className="red-btn"
            onClick={goToRedirectUrl}
          >
            Przejdź do WLS.GG
          </Button>
        </div>
      </div>
      <ExternalTournamentRegistrationModal
        redirectUrl={redirectUrl}
        show={showModal}
      />
    </div>
  );
}
